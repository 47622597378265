export default {
	init: () => {

        ///////////////////////////////////////////////
        ///////////////////////////////////////////////
		const formContainer = document.querySelector('.form__container');
        const formEl = document.querySelector('.form__suggestion')


        formEl.addEventListener('submit', function(e){
            e.preventDefault();

            let formData = new FormData(formEl)



            fetch(wordpress_vars.ajax_url, {
                method: "POST",
                body: new URLSearchParams(formData),
            })
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					formContainer.classList.add('--submited');
				}
			})
			.catch((error) => {
				console.error(error);
            });
        })
        

	}
}