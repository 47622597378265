export default {
	init: (Swiper, Navigation) => {

        ///////////////////////////////////////////////
        ///////////////////////////////////////////////
        const sliderVideosEl = document.querySelector('.js__videos-slider');

        const swiperVideos = new Swiper(sliderVideosEl, {
            modules: [Navigation],
            navigation: {
                nextEl: '.js__videos-next',
                prevEl: '.js__videos-prev',
            },
            breakpoints: {
                991: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            }
        })
        

	}
}