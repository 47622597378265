export default {
	init: (utils) => {

        // Question id params in URL
        ///////////////////////////////////////////////
        ///////////////////////////////////////////////
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const questionIdParams = urlParams.get('question_id');

        if (questionIdParams) {
            
            const questionEl = $(`#${questionIdParams}`);

            $('html, body').animate({
                scrollTop: questionEl.offset().top - 100
            }, 1000);

            questionEl.find('.question__body').slideDown();

        }


        //Question item
        ///////////////////////////////////////////////
        ///////////////////////////////////////////////
        $('.question__header-btn').on('click', function(){
            const content = $(this).closest('.question__item').find('.question__body')
            content.slideToggle()
        })
        

	}
}