// Modules
///////////////////////////////////////////////
///////////////////////////////////////////////
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// Utils
///////////////////////////////////////////////
///////////////////////////////////////////////
import * as utils from './utils/utils';
import Router from './utils/router';

// Pages
///////////////////////////////////////////////
///////////////////////////////////////////////
import main from './pages/main';
import faq from './pages/faq';

// Components
///////////////////////////////////////////////
///////////////////////////////////////////////
import videos from './components/b5-videos';
import form from './components/b12-form';

// Routing
///////////////////////////////////////////////
///////////////////////////////////////////////
const routes = new Router([
    {
        'file': main,
        'dependencies': [utils]
    },
	{
        'file': videos,
        'dependencies': [Swiper, Navigation],
		'resolve': '.videos'
    },
    {
        'file': form,
        'dependencies': [utils],
		'resolve': '.form__suggestion'
    },
    {
        'file': faq,
        'dependencies': [utils],
		'resolve': '.tpl__faq'
    },
]);

window.addEventListener('DOMContentLoaded', () => routes.load())


