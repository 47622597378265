export default {
	init: (utils) => {

        //CSS Variables
        ///////////////////////////////////////////////
        ///////////////////////////////////////////////
        window.addEventListener("DOMContentLoaded", (event) => {
            utils.setCSSVariables()
        });

        window.addEventListener("resize", (event) => {
            utils.setCSSVariables()
        });

        //Header
        ///////////////////////////////////////////////
        ///////////////////////////////////////////////
        $('.js__navdrop-btn').on('click', function(e){
            e.preventDefault();

            const isActive = $(this).hasClass('--active');

            $('.js__navdrop-btn.--active').removeClass('--active');
            $('.header__nav-drop.--active').removeClass('--active');

            if (!isActive) {
                $(this).toggleClass('--active')
                $(this).parent().find('.header__nav-drop').toggleClass('--active')
            }
            
        })

        //Hide submenu on document click
        $(document).on('click', function(e){
            if (e.target.closest('.header__bottom-nav')) return;

            $('.js__navdrop-btn').removeClass('--active')
            $('.header__nav-drop').removeClass('--active')
        })

        //Mobile menu
        ///////////////////////////////////////////////
        ///////////////////////////////////////////////
        $('.js__burger-btn').on('click', function(e){
            e.preventDefault();

            if ($(this).hasClass('--active')) {
                $(this).removeClass('--active')
                $('.menu__navigation').removeClass('--active')
                $('body').removeClass('--hidden')
            } else {
                $(this).addClass('--active')
                $('.menu__navigation').addClass('--active')
                $('body').addClass('--hidden')
            }
           
        })

        $('.js__mobile-link').on('click', function(e){
            e.preventDefault();

            $(this).toggleClass('--active')
            $(this).parent().find('.mobile__nav-drop').slideToggle()
        })

        $('.js__search-btn').on('click', function(e){
            e.preventDefault();

            $('.header__middle-form').slideToggle()
        })

        //Footer
        ///////////////////////////////////////////////
        ///////////////////////////////////////////////
        $('.footer__nav-title').on('click', function(e){
            e.preventDefault();
            if(window.innerWidth > 767) return;
            $(this).parent().find('.footer__nav-drop').slideToggle()
        })

	}
}