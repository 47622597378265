/**
 * @function
 * @name setCSSVariables Set dynamic css variables
 */
export function setCSSVariables(){

    let root = document.documentElement;
    const header = document.querySelector('.header')

    root.style.setProperty('--viewport-height', window.innerHeight + "px");

    if (header) {
        root.style.setProperty('--header-height', header.offsetHeight + "px"); 
    }

}

/**
 * @function
 * @name outerHeight Get height with margin top and bottom
 */
export function outerHeight(el) {
    let height = el.offsetHeight;
    const style = getComputedStyle(el);
  
    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  }